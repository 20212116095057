import { createStore } from 'vuex'
import { useStorage } from 'vue3-storage'

const storage = useStorage()

export default createStore({
  state: {
    account: null,
    loading: false
  },
  getters: {
    getUser(state) {
      if (state.account !== null) {
        return state.account
      } else if (storage.hasKey('userData')) {
        state.account = storage.getStorageSync('userData')
        return state.account
      } else {
        return null
      }
    }
  },
  mutations: {
    setUserData(state, userData) {
      state.account = userData
    },
    setLoading(state, loading) {
      state.loading = loading
    }
  },
  actions: {
    afterSuccessLogin({ commit }, userData) {
      commit('setUserData', userData)
    },
    setLoading({ commit }, loading) {
      commit('setLoading', loading)
    }
  },
  modules: {}
})
