<template>
  <div class="flex flex-col md:flex-row gap-5 justify-between">
    <DashboardMetricCard
      icon="donations-total"
      customClass="w-[48px] h-[48px]"
      :value="dashboardMetrics.total_donations"
      title="Total Donations"
      :metrics="totalDonations"
      :loading="loading"
      :as-currency="true"
    />
    <DashboardMetricCard
      icon="products-purchased"
      customClass="w-[50px] h-[50px]"
      :value="dashboardMetrics.products_purchased"
      title="Products Purchased"
      :metrics="productsPurchased"
      :loading="loading"
    />
    <DashboardMetricCard
      icon="animals-helped"
      customClass="w-[50px] h-[50px]"
      :value="dashboardMetrics.animals_helped"
      title="Animals Helped"
      :metrics="animalsHelped"
      :loading="loading"
    />
  </div>
</template>

<script>
import DashboardMetricCard from './DashboardMetricCard.vue'
import { getDashboardMetrics } from '@/api/donor'

export default {
  name: 'DashboardMetrics',
  components: {
    DashboardMetricCard
  },
  computed: {
    totalDonations() {
      return [
        {
          bgClass: 'bg-secondary-red',
          title: 'Monetary',
          percentage: Math.round(this.dashboardMetrics.monetary_donations)
        },
        {
          bgClass: 'bg-secondary-sand',
          title: 'Wishlist',
          percentage: Math.round(this.dashboardMetrics.wishlist_donations)
        },
        {
          bgClass: 'bg-secondary-light-blue',
          title: 'Subscription',
          percentage:
            100 -
            (Math.round(this.dashboardMetrics.monetary_donations) +
              Math.round(this.dashboardMetrics.wishlist_donations))
        }
      ]
    },
    productsPurchased() {
      return [
        {
          bgClass: 'bg-secondary-red',
          title: 'Food',
          percentage: Math.round(this.dashboardMetrics.food)
        },
        {
          bgClass: 'bg-secondary-sand',
          title: 'Health',
          percentage: Math.round(this.dashboardMetrics.health)
        },
        {
          bgClass: 'bg-secondary-light-blue',
          title: 'Others',
          percentage:
            100 -
            (Math.round(this.dashboardMetrics.food) +
              Math.round(this.dashboardMetrics.health))
        }
      ]
    },
    animalsHelped() {
      return [
        {
          bgClass: 'bg-secondary-red',
          title: 'Cats',
          percentage: Math.round(this.dashboardMetrics.cats)
        },
        {
          bgClass: 'bg-secondary-sand',
          title: 'Dogs',
          percentage: Math.round(this.dashboardMetrics.dogs)
        },
        {
          bgClass: 'bg-secondary-light-blue',
          title: 'Others',
          percentage:
            100 -
            (Math.round(this.dashboardMetrics.cats) +
              Math.round(this.dashboardMetrics.dogs))
        }
      ]
    }
  },
  data() {
    const userInfo = this.$store.getters.getUser
    return {
      dashboardMetrics: {
        animals_helped: 0,
        cats: 0,
        dogs: 0,
        food: 0,
        health: 0,
        monetary_donations: 0,
        other_animals: 0,
        other_tags: 0,
        products_purchased: 0,
        subscription_donations: 0,
        total_donations: 0,
        wishlist_donations: 0
      },
      loading: true,
      donorName: userInfo.name ? userInfo.name : 'Donor'
    }
  },
  async created() {
    const { status, data } = await getDashboardMetrics()

    if (status === 200) {
      this.dashboardMetrics = { ...data }
    }

    this.loading = false
  }
}
</script>
