<template>
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    v-bind="$attrs"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 9l4-4 4 4m0 6l-4 4-4-4"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSelector'
}
</script>
