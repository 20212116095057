<template>
  <svg
    viewBox="0 0 50 50"
    width="50"
    height="50"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    v-bind="$attrs"
  >
    <path
      d="M45.2001 10.6241L25.8171 4.26664C25.4614 4.13329 25.1057 4.13329 24.7501 4.26664L5.36707 10.6241C4.6557 10.8464 4.16667 11.5131 4.16667 12.2689V37.7424C4.16667 38.4981 4.65571 39.1651 5.36707 39.3872L24.7501 45.7443C24.9278 45.7886 25.1058 45.8333 25.2834 45.8333C25.4611 45.8333 25.6391 45.7889 25.8168 45.7443L45.1998 39.3872C45.9112 39.1648 46.4002 38.4981 46.4002 37.7424V12.2689C46.4002 11.5131 45.9112 10.8464 45.1998 10.6241H45.2001ZM25.2836 7.68986L39.154 12.2689L25.2836 16.8035L20.9713 15.3807L23.9942 14.2693C24.8833 13.958 25.328 12.9356 25.0166 12.0465C24.7053 11.1575 23.6829 10.7127 22.7938 11.0241L15.6806 13.6026L11.4127 12.1798L25.2836 7.68986ZM7.58997 14.625L13.8584 16.6701V30.8073C13.8584 31.741 14.6141 32.5411 15.5922 32.5411C16.5703 32.5411 17.326 31.7853 17.326 30.8073V17.826L23.5944 19.8711V41.6992L7.59013 36.4532L7.58997 14.625ZM36.7089 38.5425V29.6068C36.7089 28.673 35.9532 27.873 34.9751 27.873C34.0414 27.873 33.2413 28.6287 33.2413 29.6068L33.241 39.6539L26.9726 41.6991L26.9729 19.871L42.9327 14.6253V36.4534L36.7089 38.5425Z"
      fill="#2A415B"
      stroke-width="0"
    />
    <path
      d="M15.5002 33.9999V15.4999L27.0002 11.4999"
      stroke="#FF696E"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.0002 26.9999V38.9999"
      stroke="#FF696E"
      stroke-width="5"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconProductsPurchased'
}
</script>
