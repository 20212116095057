<template>
  <footer class="right-0 left-0 bottom-0 w-full absolute" v-bind="$attrs">
    <div class="body-text-1 text-center h-10 py-2 z-10">
      Copyrights © 2022 CUDDLY. All rights reserved.
    </div>
  </footer>
</template>

<script>
export default {
  name: 'DashboardFooter'
}
</script>
