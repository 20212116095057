const numericOnly = {
  mounted(el, binding) {
    if (binding.value === true) {
      el.addEventListener('keypress', (e) => {
        if (isNaN(e.key)) {
          e.preventDefault()
        }
      })
    }
  },
  unmounted(el, binding) {
    if (binding.value === true) {
      el.removeEventListener('keypress', (e) => {
        if (isNaN(e.key)) {
          e.preventDefault()
        }
      })
    }
  }
}

export { numericOnly }
