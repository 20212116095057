<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
export default {
  name: 'App',
  computed: {
    layout() {
      return (this.$route.meta.layout || 'dashboard') + '-layout'
    }
  },
  created() {
    const userInfo = this.$storage.hasKey('userData')
      ? this.$storage.getStorageSync('userData')
      : null

    this.$store.dispatch('afterSuccessLogin', userInfo)
    let routeName = (window.location.pathname.split('/')).length > 0 ? window.location.pathname.split('/')[1] : '';

    const allowedRoutes = ['login', 'forgot-password', 'reset-password']

    if (userInfo === null && !allowedRoutes.includes(routeName)) {
      this.$router.push('/login')
    }
  }
}
</script>
