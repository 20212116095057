<template>
  <div
    class="flex flex-col md:flex-row bg-white max-w-[1100px] m-auto shadow-2 mt-5 rounded-2xl overflow-hidden mb-20"
  >
    <div class="flex flex-col md:w-1/2">
      <img
        alt="CUDDLY Club"
        src="@/assets/cuddly-club-mutt-scouts.png"
        class="w-full md:w-[380px] h-[250px] object-cover"
      />
      <div
        class="flex w-full lg:w-[380px] h-[183px] md:h-full md:items-center bg-secondary-light-blue px-[72.75px] pt-[25px] pb-[28px]"
      >
        <img
          alt="CUDDLY Club"
          src="@/assets/cuddly-club.png"
          class="max-w-[234.51px] max-h-[130px] h-full"
        />
      </div>
    </div>
    <div
      class="flex flex-col relative w-full md:w-1/2 lg:w-full h-auto lg:h-[433px]"
    >
      <BaseIcon
        name="close"
        class="absolute top-5 right-5 text-gray-900 h-6 w-6 cursor-pointer"
        @click="handleCancel"
      />
      <div
        v-if="cancelled"
        class="flex flex-col w-full h-full items-start justify-center py-[133px] md:py-[154px] px-[25px] lg:px-[60px]"
      >
        <h3
          class="text-brand-navy font-extrabold text-[30px] leading-45 mb-2.5"
        >
          Thank you.
        </h3>
        <span class="subtitle-1 mb-10 text-gray-700">
          You have successfully unsubscribed from CUDDLY Club.
        </span>
        <span class="subtitle-1 text-gray-700">
          Did you cancel your subscription by accident?
        </span>
        <div>
          <BaseButton
            id="back-to-dashboard"
            @click="handleCancel"
            variant="text"
            class="p-0 uppercase"
          >
            <span class="underline underline-offset-4">
              Join CUDDLY Club here
            </span>
          </BaseButton>
        </div>
      </div>
      <div
        v-else
        class="flex flex-col my-[50px] lg:my-[94px] mx-[45px] lg:mx-[60px]"
      >
        <h3 class="text-brand-navy font-extrabold text-[30px] leading-45">
          We are sorry to see you to go...
        </h3>
        <span class="subtitle-1 mt-2.5 text-gray-700">
          Do you really want to cancel your future donation through CUDDLY Club?
          Would you like to pause for...
        </span>
        <div
          class="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-[15px] mt-4 mb-[30px]"
        >
          <div
            v-for="option in options"
            :key="option.id"
            class="flex justify-center items-center py-2.5 px-[15px] max-w-[190px] h-[47px] rounded-[5px] bg-[#F2F2F2] cursor-pointer"
            @click="handleMonthsClick(option.value)"
            :class="{
              'border border-black': option.value === months
            }"
          >
            <h6>{{ option.value }}</h6>
          </div>
        </div>
        <div class="flex flex-col lg:flex-row gap-5 justify-end">
          <BaseButton
            id="dismiss"
            variant="outlined"
            class="py-[18px] px-[66px] md:px-[60px] lg:px-[66px] uppercase w-full lg:max-w-max max-h-[50px]"
            @click="handleCancel"
          >
            No Thanks
          </BaseButton>
          <BaseButton
            id="save"
            class="py-[18px] px-[66px] md:px-[20px] lg:px-[66px] uppercase lg:max-w-max max-h-[50px]"
            @click="handleSave"
            :loading="loading"
            :disabled="loading"
          >
            Save Changes
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import BaseIcon from '@/components/Base/BaseIcon.vue'
import {cancelSubscription, pauseSubscription} from "@/api/subscriptions";

export default {
  name: 'DashboardSubscriptionCancel',
  components: {
    BaseButton,
    BaseIcon
  },
  props: {
    subscription: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      months: 0,
      cancelled: false,
      loading: false,
      options: [
        {
          id: 'pause-1',
          value: 1
        },
        {
          id: 'pause-3',
          value: 3
        },
        {
          id: 'pause-6',
          value: 6
        }
      ]
    }
  },
  methods: {
    handleCancel() {
      this.months = 0
      this.loading = false
      this.cancelled = false
      this.$emit('dismiss')
    },
    async handleCancelSubscription() {
      this.loading = true
      const subscriptionId = this.subscription.subscription_id
      const { status } = await cancelSubscription(subscriptionId)

      if (status !== 200) {
        this.$toast.error('Whoops, looks like something went wrong.')
      }

      this.loading = false
      this.cancelled = true
    },
    async handleSave() {
      this.loading = true

      const { status } = await pauseSubscription(this.months, this.subscription.subscription_id)

      if (status !== 200) {
        this.$toast.error('Whoops, looks like something went wrong.')
      }

      this.loading = false
      this.cancelled = true
    },
    handleMonthsClick(value) {
      this.months = value
    }
  }
}
</script>
