<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="$attrs"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.7568 2.03351C12.034 2.10713 12.2662 2.29624 12.3945 2.55279C12.8781 3.51991 13.239 4.10688 13.6021 4.5828C13.9696 5.06453 14.3584 5.45889 14.9497 6.05028C16.3164 7.41692 17 9.21013 17 11C17 12.7899 16.3164 14.5831 14.9497 15.9498C12.2161 18.6834 7.78392 18.6834 5.05025 15.9498C3.68361 14.5831 3 12.7899 3 11C3 9.21013 3.68361 7.41692 5.05025 6.05028C5.33625 5.76428 5.76637 5.67872 6.14004 5.83351C6.51372 5.98829 6.75736 6.35292 6.75736 6.75739C6.75736 7.8772 6.82729 8.73046 7.15481 9.41104C7.33491 9.78527 7.61585 10.1512 8.10004 10.4776C8.21568 9.41687 8.42752 8.12448 8.71375 6.89778C8.93911 5.93195 9.21939 4.96773 9.553 4.16366C9.71986 3.76148 9.90855 3.37854 10.1229 3.04791C10.3314 2.72608 10.5995 2.39858 10.9454 2.16795C11.184 2.00885 11.4795 1.95988 11.7568 2.03351ZM12.1213 15.1213C10.9497 16.2929 9.05025 16.2929 7.87868 15.1213C7.29289 14.5355 7 13.7678 7 13C7 13 7.87868 13.5 9.50005 13.5C9.50005 12.5 10 9.5 10.75 9C11.25 10 11.5355 10.2929 12.1213 10.8787C12.7071 11.4645 13 12.2322 13 13C13 13.7678 12.7071 14.5355 12.1213 15.1213Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconFire'
}
</script>
