<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8486 0.751496C10.3799 0.282867 9.62013 0.282867 9.1515 0.751496L0.751496 9.1515C0.282867 9.62013 0.282867 10.3799 0.751496 10.8486C1.22013 11.3172 1.97992 11.3172 2.44855 10.8486L2.80002 10.4971V18.4C2.80002 19.0628 3.33728 19.6 4.00002 19.6H6.40002C7.06277 19.6 7.60002 19.0628 7.60002 18.4V16C7.60002 15.3373 8.13728 14.8 8.80002 14.8H11.2C11.8628 14.8 12.4 15.3373 12.4 16V18.4C12.4 19.0628 12.9373 19.6 13.6 19.6H16C16.6628 19.6 17.2 19.0628 17.2 18.4V10.4971L17.5515 10.8486C18.0201 11.3172 18.7799 11.3172 19.2486 10.8486C19.7172 10.3799 19.7172 9.62013 19.2486 9.1515L10.8486 0.751496Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconHome'
}
</script>
