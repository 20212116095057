<template>
  <svg
    viewBox="0 0 24 22"
    width="24"
    height="22"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    v-bind="$attrs"
  >
    <path
      d="M20.84 3.61012C20.3292 3.09912 19.7228 2.69376 19.0554 2.4172C18.3879 2.14064 17.6725 1.99829 16.95 1.99829C16.2275 1.99829 15.5121 2.14064 14.8446 2.4172C14.1772 2.69376 13.5708 3.09912 13.06 3.61012L12 4.67012L10.94 3.61012C9.9083 2.57842 8.50903 1.99883 7.05 1.99883C5.59096 1.99883 4.19169 2.57842 3.16 3.61012C2.1283 4.64181 1.54871 6.04108 1.54871 7.50012C1.54871 8.95915 2.1283 10.3584 3.16 11.3901L4.22 12.4501L12 20.2301L19.78 12.4501L20.84 11.3901C21.351 10.8794 21.7563 10.2729 22.0329 9.60547C22.3095 8.93801 22.4518 8.2226 22.4518 7.50012C22.4518 6.77763 22.3095 6.06222 22.0329 5.39476C21.7563 4.7273 21.351 4.12087 20.84 3.61012V3.61012Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconHeart'
}
</script>
