<template>
  <slot />
</template>

<script>
export default {
  name: 'LoginLayout'
}
</script>

<style>
body {
  padding-bottom: unset !important;
}
</style>
