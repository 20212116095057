<template>
  <div v-show="editSubscription">
    <DashboardSubscriptionDetails
      :subscription="subscriptionForDetails"
      :onDismiss="handleDismiss"
    />
  </div>

  <div class="flex flex-col xl:flex-row gap-5" v-show="!editSubscription">
    <div class="flex flex-col flex-1">
      <div class="w-full mb-[30px]">
        <DashboardWelcomeBack />
      </div>
      <div class="w-full mb-10">
        <DashboardMetrics />
      </div>
      <div class="w-full mb-10">
        <DashboardSubscriptions
          :onEdit="handleEdit"
          @hasSubscription="updateSubscribedDonorValue"
        />
      </div>
      <div class="w-full mb-10">
        <DashboardCampaignsDonated />
      </div>
    </div>
    <div class="flex flex-col flex-0 lg:min-w-[380px] min-h-screen gap-[30px]">
      <DashboardCuddlyClubCard :subscribed="isSubscribed" />
      <!-- <DashboardRecentlyViewed /> -->
    </div>
  </div>
  <DashboardFooter />
</template>

<script>
import DashboardWelcomeBack from '@/components/Dashboard/DashboardWelcomeBack.vue'
import DashboardMetrics from '@/components/Dashboard/DashboardMetrics.vue'
import DashboardCuddlyClubCard from '@/components/Dashboard/DashboardCuddlyClubCard.vue'
import DashboardCampaignsDonated from '@/components/Dashboard/DashboardCampaignsDonated.vue'
import DashboardFooter from '@/components/Dashboard/DashboardFooter.vue'
import DashboardSubscriptions from '@/components/Dashboard/DashboardSubscriptions.vue'
// import DashboardRecentlyViewed from '@/components/Dashboard/DashboardRecentlyViewed.vue'
import DashboardSubscriptionDetails from '@/components/Dashboard/DashboardSubscriptionDetails.vue'

export default {
  name: 'DashboardView',
  components: {
    DashboardWelcomeBack,
    DashboardMetrics,
    DashboardFooter,
    DashboardCuddlyClubCard,
    DashboardSubscriptions,
    DashboardCampaignsDonated,
    // DashboardRecentlyViewed,
    DashboardSubscriptionDetails
  },
  data() {
    return {
      editSubscription: false,
      isSubscribed: false,
      subscriptionForDetails: {}
    }
  },
  methods: {
    handleEdit(subscription) {
      this.editSubscription = true
      this.subscriptionForDetails = subscription
    },
    handleDismiss() {
      this.editSubscription = false
    },
    updateSubscribedDonorValue(value) {
      this.isSubscribed = value
    }
  }
}
</script>
