<template>
  <div class="flex-1 w-full md:min-w-max mb-5">
    <h4>My Subscriptions</h4>
  </div>
  <div class="flex flex-col xl:flex-row gap-5 flex-1 w-full justify-between">
    <div class="flex flex-col flex-1 space-y-[30px]">
      <div
        v-if="subscriptions.length < 1"
        class="flex flex-col w-full h-auto bg-white shadow-1 rounded-lg py-[30px] px-[26px]"
      >
        <div class="flex w-full justify-center mb-2.5">
          <span class="body-text-1 text-brand-navy">
            Don't Miss Our Do-good Subscriptions!
          </span>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div
            class="flex flex-col w-full h-auto bg-white shadow-md rounded-lg p-6"
          >
            <div
              class="flex items-start justify-center w-full h-48 md:h-36 lg:h-48 bg-secondary-light-blue pt-1 overflow-hidden rounded-lg"
            >
              <img
                alt="CUDDLY Club"
                src="@/assets/cuddly-club.png"
                class="w-[305px] md:w-[290px] lg:w-[305px] h-[176.95px] md:h-auto lg:h-[176.95px]"
              />
            </div>
            <div class="mt-[26px] mb-[18px]">
              <span
                class="w-[203px] h-[25px] rounded-md px-2.5 py-0.5 bg-secondary-sand body-text-2 text-brand-navy"
              >
                Monthly Giving Subscription
              </span>
            </div>
            <h5>CUDDLY CLUB</h5>
            <ul
              class="list-disc body-text-1 text-gray-500 mt-[10px] mb-4 px-6 h-[216px]"
            >
              <li>
                Donations are pooled together to make a more significant impact
                and help to save as many animals as possible.
              </li>
              <li>
                CUDDLY sends the supplies & funds directly to the animal
                rescues.
              </li>
              <li>
                CUDDLY Club members receive weekly updates on the animals they
                have helped.
              </li>
            </ul>
            <div class="flex w-full justify-center mb-4">
              <a
                class="custom-underline"
                target="_blank"
                rel="noopener noreferrer"
                :href="cuddlyClubUrl"
              >
                Learn More
              </a>
            </div>
            <div class="w-full">
              <BaseButton
                id="join-cuddly-club"
                class="w-full uppercase h-[49.5px]"
                @click="goToCheckout"
              >
                Join CUDDLY Club
              </BaseButton>
            </div>
          </div>
          <div
            class="flex flex-col w-full h-auto bg-white shadow-md rounded-lg p-6"
          >
            <div
              class="flex relative items-start justify-center w-full h-48 md:h-36 lg:h-48"
            >
              <img
                alt="CUDDLY Club"
                src="@/assets/full-bowls-club-rectangle.png"
                class="w-[305px] h-full md:w-[290px] lg:w-[305px]"
              />
              <div class="absolute bottom-0 w-full px-4 pb-[15.36px]">
                <h4 class="text-white text-right">Full Bowls<br />Club</h4>
              </div>
            </div>
            <div class="mt-[26px] mb-[18px]">
              <span
                class="w-[203px] h-[25px] rounded-md px-2.5 py-0.5 bg-secondary-sand body-text-2 text-brand-navy"
              >
                Monthly Giving Subscription
              </span>
            </div>
            <h5>FULL BOWLS CLUB</h5>
            <ul
              class="list-disc body-text-1 text-gray-500 mt-[10px] mb-4 px-6 h-[216px]"
            >
              <li>Support a specific organization each month.</li>
              <li>
                The funds provide precisely what they need from the Wishlist
                items for pets and families in need.
              </li>
              <li>Help multiple animals within the organization of choice.</li>
            </ul>
            <div class="flex w-full justify-center mb-4">
              <span class="custom-underline">Learn More</span>
            </div>
            <div class="w-full">
              <BaseButton
                id="join-full-bowls-club"
                class="w-full uppercase h-[49.5px]"
              >
                Join Full Bowls Club
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <DashboardSubscriptionCard
          v-for="subscription in subscriptions"
          :key="subscription.id"
          :subscription="subscription"
          :onEdit="onEdit"
        />
      </template>
    </div>
  </div>
</template>

<script>
import DashboardSubscriptionCard from '@/components/Dashboard/DashboardSubscriptionCard.vue'
import BaseButton from '@/components/Base/BaseButton.vue'
import { getSubscriptions } from '@/api/subscriptions'
import { ref } from 'vue'

export default {
  name: 'DashboardSubscriptions',
  components: {
    DashboardSubscriptionCard,
    BaseButton
  },
  props: {
    onEdit: {
      type: Function,
      required: true
    }
  },
  setup(props, { emit }) {
    const subscriptions = ref([])
    const subscriptionsLoading = ref(false)

    const fetchSubscriptions = async () => {
      subscriptionsLoading.value = true
      const { data } = await getSubscriptions()
      subscriptions.value = data

      if (data.length > 0) {
        emit('hasSubscription', true)
      }

      subscriptionsLoading.value = false
    }

    fetchSubscriptions()

    return {
      subscriptions,
      subscriptionsLoading,
      cuddlyClubUrl: `${process.env.VUE_APP_CUDDLY_URL}/club`
    }
  },
  methods: {
    goToCheckout() {
      window.open(
        `${process.env.VUE_APP_CUDDLY_URL}/club`,
        '_blank',
        'noopener,noreferrer'
      )
    }
  }
}
</script>

<style scoped>
.custom-underline {
  position: relative;
  display: block;
  text-transform: uppercase;
  letter-spacing: 5px;
  width: max-content;
  color: #2a415b;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
}
.custom-underline:hover {
  color: #ff696e;
}
.custom-underline:after {
  content: '';
  border-bottom: 1px solid #2a415b;
  padding-bottom: 1px;
  display: block;
  position: absolute;
  right: 5px;
  left: 0;
}
.custom-underline:hover::after {
  border-bottom: 1px solid #ff696e;
  color: #2a415b;
}
</style>
