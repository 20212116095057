<template>
  <DashboardLoadingBar />
  <DashboardNavBar />
  <DashboardBasicProfile />
  <main class="mx-5 md:mx-16 lg:mx-[120px] pb-12 antialiased">
    <div class="mt-8">
      <slot />
    </div>
  </main>
</template>

<script>
import DashboardLoadingBar from '@/components/Dashboard/DashboardLoadingBar.vue'
import DashboardNavBar from '@/components/Dashboard/DashboardNavBar.vue'
import DashboardBasicProfile from '@/components/Dashboard/DashboardBasicProfile.vue'

export default {
  name: 'DashboardLayout',
  components: {
    DashboardLoadingBar,
    DashboardNavBar,
    DashboardBasicProfile
  }
}
</script>
