<template>
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    v-bind="$attrs"
  >
    <path d="M3 12H21" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3 6H21" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3 18H21" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'IconMenu'
}
</script>
