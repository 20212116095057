export default {
  methods: {
    formatCurrency: function (
      value,
      { currency = 'USD', locale = 'en-US', decimals = true } = {}
    ) {
      if (value === null || value === undefined) {
        return ''
      }

      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: decimals ? 2 : 0
      }).format(Math.abs(value))
    }
  }
}
