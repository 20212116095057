<template>
  <Disclosure as="nav" class="bg-white" v-slot="{ open }">
    <div
      class="mx-auto px-5 md:px-0 md:mx-16 lg:mx-[120px] antialiased border-gray-300"
    >
      <div class="relative flex h-16 justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center rounded-md text-cool-gray-900 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-pink"
          >
            <span class="sr-only">Open main menu</span>
            <BaseIcon
              name="menu"
              v-if="!open"
              class="h-6 w-6"
              aria-hidden="true"
            />
            <BaseIcon name="close" v-else class="h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div
          class="flex flex-1 items-center justify-center sm:items-stretch lg:justify-start sm:ml-20 lg:ml-0 -mb-[1px]"
        >
          <div class="flex flex-shrink-0 items-center justify-center">
            <img
              class="block h-6 w-auto lg:hidden"
              src="@/assets/cuddly.png"
              alt="CUDDLY"
            />
            <img
              class="hidden h-6 w-auto lg:block"
              src="@/assets/cuddly.png"
              alt="CUDDLY"
            />
          </div>
          <div class="hidden lg:ml-6 lg:flex sm:space-x-8">
            <router-link
              to="/"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium focus-visible:outline-none"
            >
              Dashboard
            </router-link>
            <router-link
              to="/donation-history"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium focus-visible:outline-none"
            >
              Donation History
            </router-link>
            <router-link
              to="/profile"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium focus-visible:outline-none"
            >
              Profile
            </router-link>
          </div>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <!-- <button
            type="button"
            class="hidden lg:block rounded-full bg-white p-1 text-brand-navy hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand-pink focus:ring-offset-2"
          >
            <span class="sr-only">View notifications</span>
            <BaseIcon
              name="bell"
              class="h-6 w-6 text-gray-500"
              title="Notifications"
            />
          </button> -->

          <!-- Profile dropdown -->
          <MenuUI as="div" class="relative ml-3">
            <div>
              <MenuButton class="flex items-center rounded-full bg-white">
                <span class="sr-only">Open user menu</span>
                <img
                  class="h-10 w-10 rounded-full"
                  :src="logo"
                  alt=""
                  v-if="logo"
                />
                <BaseIcon
                  name="profile"
                  class="h-10 w-10 text-brand-navy"
                  v-else
                />
                <BaseIcon
                  name="chevron-down"
                  class="hidden lg:block ml-2 h-5 w-5 text-brand-navy"
                  title="open menu"
                />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-5 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <router-link
                  to="/profile"
                  class="block px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <MenuItem>
                    <a href="#">Edit Profile</a>
                  </MenuItem>
                </router-link>
                <router-link
                  to="/help-center"
                  class="block px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <MenuItem>
                    <a href="#">Help Center</a>
                  </MenuItem>
                </router-link>
                <router-link
                  to="/contact-us"
                  class="block px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <MenuItem>
                    <a href="#">Contact Us</a>
                  </MenuItem>
                </router-link>
                <MenuItem
                  class="block px-4 py-2 text-sm text-red-500 hover:bg-gray-50 border-t border-t-gray-200"
                >
                  <a href="#" @click.stop.prevent="logout">Sign Out</a>
                </MenuItem>
              </MenuItems>
            </transition>
          </MenuUI>
        </div>
      </div>
    </div>

    <DisclosurePanel class="lg:hidden">
      <div class="space-y-1 pt-2 pb-4">
        <router-link
          to="/"
          class="block border-l-4 border-b-none border-transparent py-2 pl-3 pr-4 text-sm font-normal text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
        >
          Dashboard
        </router-link>
        <router-link
          to="/donation-history"
          class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-sm font-normal text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
        >
          Donation History
        </router-link>
        <router-link
          to="/profile"
          class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-sm font-normal text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
        >
          Profile
        </router-link>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu as MenuUI,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue'
import BaseIcon from '@/components/Base/BaseIcon.vue'
import { donorLogout } from '@/api/auth'
import { profileUpdated } from '@/mixins/profileUpdated'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuUI,
    MenuButton,
    MenuItem,
    MenuItems,
    BaseIcon
  },
  mixins: [profileUpdated],
  data() {
    const userInfo = this.$store.getters.getUser

    return {
      loggingOut: false,
      logo: userInfo?.logo || null
    }
  },
  methods: {
    async logout() {
      if (this.loggingOut !== true) {
        this.loggingOut = true
        this.$store.dispatch('setLoading', true)
        const userToken = this.$store.getters.getUser.token
        await donorLogout(userToken)

        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('afterSuccessLogin', null)
        this.$storage.removeStorageSync('userData')
        this.$router.go()
      }
    }
  }
}
</script>

<style scoped>
.router-link-active {
  border-color: #ff696e;
  color: #4d4d4d;
}
.router-link-active:hover {
  border-color: #ff696e;
  color: #4d4d4d;
}
</style>
