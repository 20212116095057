<template>
  <div class="flex items-center">
    <input
      type="checkbox"
      :checked="modelValue"
      :disabled="disabled"
      :class="checkboxClasses"
      :id="id"
      :name="id"
      :data-testid="id"
      v-bind="$attrs"
      @click="this.$emit('update:modelValue', $event.target.checked)"
    />
    <label
      :for="id"
      class="text-gray-900 text-sm font-medium leading-21 ml-2.5 cursor-pointer"
      :class="{ 'text-red-500': error }"
    >
      <slot />
    </label>
  </div>
  <span
    role="alert"
    class="block text-sm text-red-600 font-normal leading-5"
    v-if="error"
  >
    {{ error }}
  </span>
</template>

<script>
const CHECKBOX_CLASSES =
  'h-4 w-4 text-xs rounded border border-gray-300 bg-gray-50 focus:ring-transparent cursor-pointer'
const CHECKBOX_VARIANTS = {
  primary: `${CHECKBOX_CLASSES} text-brand-navy`,
  secondary: `${CHECKBOX_CLASSES} text-coral-500`
}

export default {
  name: 'BaseCheckbox',
  props: {
    id: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: 'primary'
    },
    error: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [Boolean, String, Number],
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  },
  beforeMount() {
    if (this.id === undefined) {
      throw new Error('BaseCheckbox requires an id')
    }
  },
  computed: {
    checkboxClasses() {
      return CHECKBOX_VARIANTS[this.variant.toLowerCase()]
    }
  }
}
</script>
