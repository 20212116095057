export default {
  methods: {
    toTitleCase: function (str) {
      if (str === null || str === undefined) {
        return ''
      }

      str = str.replace(/[-_]/g, ' ')
      str = str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })

      return str
    }
  }
}
