import axios from 'axios'
import store from '@/store'

const token = store.getters.getUser?.token || ''

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BACKEND,
  headers: {
    Authorization: token ? `Bearer ${token}` : undefined
  }
})

api.interceptors.request.use(
  (config) => {
    store.commit('setLoading', true)

    return config
  },
  (error) => {
    store.commit('setLoading', false)

    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    store.commit('setLoading', false)

    return response
  },
  (error) => {
    store.commit('setLoading', false)

    return Promise.reject(error)
  }
)

const { get, post, put, delete: destroy } = api

export { get, post, put, destroy }

export default api
