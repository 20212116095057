<template>
  <svg
    viewBox="0 0 51 50"
    width="51"
    height="50"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    v-bind="$attrs"
  >
    <g clip-path="url(#clip0_0_1)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.4711 24.0273C30.5855 24.0889 34.5682 27.6196 36.4883 32.0517C37.6596 34.7622 37.4153 37.2588 36.5311 38.8731C35.6536 40.4747 32.9116 40.316 31.0904 38.9606C29.7015 37.9264 28.1374 37.1676 26.4714 37.0801C24.8019 37.1676 23.2413 37.923 21.8524 38.9606C20.0312 40.3191 17.2892 40.4779 16.4116 38.8731C15.5306 37.2585 15.2833 34.762 16.4545 32.0517C18.3746 27.6195 22.3568 24.0887 26.4717 24.0273H26.4711ZM11.7028 18.9696C9.91775 19.8222 9.43604 22.5522 10.6271 25.0649C11.8181 27.5775 14.2268 28.9297 16.0116 28.0768C17.7967 27.2242 18.2784 24.4943 17.0873 21.9815C15.8963 19.469 13.4846 18.1202 11.7028 18.9696ZM20.4067 10.0663C22.7625 9.72914 25.049 12.109 25.5142 15.3803C25.9761 18.6549 24.4419 21.5827 22.0829 21.9232C19.7272 22.2604 17.4406 19.8805 16.9755 16.6092C16.5136 13.3346 18.0512 10.4039 20.4067 10.0663ZM41.2395 18.9696C39.4544 18.117 37.0459 19.469 35.8549 21.9815C34.6639 24.4975 35.1456 27.2274 36.9306 28.0768C38.7157 28.9295 41.1242 27.5775 42.3152 25.0649C43.5062 22.5521 43.0245 19.8221 41.2395 18.9696ZM32.5355 10.0663C34.8913 10.4035 36.4287 13.3345 35.9668 16.6092C35.5049 19.8837 33.2184 22.2635 30.8593 21.9232C28.5036 21.586 26.9662 18.655 27.4281 15.3803C27.8897 12.1058 30.1763 9.72597 32.5355 10.0663Z"
        fill="#FF696E"
        stroke="#FF696E"
      />
    </g>
    <circle cx="26" cy="25" r="23.5" stroke="#2A415B" stroke-width="3" />
    <defs>
      <clipPath id="clip0_0_1">
        <rect
          width="33.0529"
          height="30.0481"
          fill="white"
          transform="translate(9.9742 9.97571)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconAnimalsHelped'
}
</script>
