import { post } from '@/config/api'

const donorLogin = async (email, password) => {
  const payload = {
    email,
    password
  }

  try {
    return await post('/api/auth/login', payload)
  } catch (e) {
    return e
  }
}

const donorExternalLogin = async (login_string) => {
  const payload = {
    login_string
  }

  try {
    return await post('/api/external-login', payload)
  } catch (e) {
    return e
  }
}

const sendResetLink = async (email) => {
  const payload = {
    email
  }

  try {
    const { status, data } =  await post('/api/password-link', payload)

    return { status, data }
  } catch (e) {
    return e
  }
}

const resetDonorsPassword = async (token, password, password_confirmation, emailHash) => {
  const payload = {
    token,
    password,
    password_confirmation,
    emailHash
  }

  try {
    const { status, data } = await post('/api/donor/password-reset', payload)

    return { status, data }
  } catch (e) {
    const data = e.response.data
    const status = e.response.status

    return { data, status }
  }
}

const donorLogout = async (token) => {
  try {
    return await post('/api/auth/logout', { token })
  } catch (e) {
    console.log('err')
    console.log(e)

    return null
  }
}

export { donorLogin, donorLogout, donorExternalLogin, sendResetLink, resetDonorsPassword}
