<template>
  <div
    class="flex flex-col md:flex-row flex-grow h-36 md:h-[98px] items-center w-full bg-white border-t border-t-gray-300 px-5 md:px-16 lg:px-[120px] shadow-1"
  >
    <div class="flex flex-1 w-full items-center h-[50px] md:h-auto">
      <img
        class="h-[50px] w-[50px] rounded-full"
        :src="logo"
        :alt="name"
        v-if="logo"
      />
      <BaseIcon name="profile" class="h-[50px] w-[50px]" v-else />
      <div class="flex flex-col ml-3 w-full">
        <h5>{{ name }}</h5>
        <div class="flex flex-col lg:flex-row md:gap-2 w-max">
          <div class="flex gap-2" v-if="phone">
            <BaseIcon
              name="phone"
              class="h-5 w-5 text-gray-500"
              title="Phone"
              fill="currentColor"
              stroke-width="0"
            />
            <span class="body-text-3">{{ phone }}</span>
          </div>
          <div class="flex gap-2">
            <BaseIcon
              name="mail"
              class="h-5 w-5 text-gray-500"
              fill="currentColor"
            />
            <span class="body-text-3">{{ email }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end flex-0 my-4 md:my-0 w-full md:w-auto">
      <BaseButton id="find-animals" class="px-[40px] py-3" @click="findAnimals">
        <span class="text-xs leading-14 tracking-[1px]">
          Find Animals to Help
        </span>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'
import { profileUpdated } from '@/mixins/profileUpdated'
import BaseButton from '@/components/Base/BaseButton.vue'

export default {
  components: {
    BaseIcon,
    BaseButton
  },
  mixins: [profileUpdated],
  data() {
    const userInfo = this.$store.getters.getUser

    return {
      logo: userInfo?.logo,
      phone: userInfo?.phone,
      name: userInfo?.name ? userInfo.name : 'Donor',
      email: userInfo?.email
    }
  },
  methods: {
    findAnimals() {
      window.open(
        `${process.env.VUE_APP_CUDDLY_URL}/donate/change-a-life`,
        '_blank',
        'noopener,noreferrer'
      )
    }
  }
}
</script>
