<template>
  <div class="flex flex-col items-center w-full">
    <h3>Hi, {{ donorName }}!</h3>
    <span class="body-text-1 text-gray-700">Welcome Back to CUDDLY!</span>
  </div>
</template>

<script>
export default {
  name: 'DashboardWelcomeBack',
  data() {
    const userInfo = this.$store.getters.getUser

    return {
      donorName: userInfo.name ? userInfo.name : 'Donor'
    }
  }
}
</script>
