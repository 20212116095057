export default {
  methods: {
    getCampaignUrl: function (campaign) {
      let url = `${process.env.VUE_APP_CUDDLY_URL}`

      if (campaign?.type === 'core') {
        url = `${url}/donate/${campaign?.id}`
      } else if (campaign?.type === 'pantry') {
        url = `${url}/pantry/${campaign?.id}`
      }

      return url
    }
  }
}
