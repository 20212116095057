const profileUpdated = {
  mounted() {
    window.addEventListener('profile-updated', (event) => {
      this.logo = event.detail.logo
      this.phone = event.detail.phone
      this.name = event.detail.name
      this.email = event.detail.email
    })
  },
  unmounted() {
    window.removeEventListener('profile-updated', (event) => {
      this.logo = event.detail.logo
      this.phone = event.detail.phone
      this.name = event.detail.name
      this.email = event.detail.email
    })
  }
}

export { profileUpdated }
