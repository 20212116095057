<template>
  <div class="w-full md:min-w-[380px] md:w-[380px] h-auto bg-white rounded-lg">
    <img
      class="w-full"
      src="@/assets/cuddly-club-mutt-scouts.png"
      alt="CUDDLY Club"
    />
    <div
      class="min-h[183.35px] h-[183.35px] w-full bg-secondary-light-blue px-[72.75px] pt-[25.05px] pb-[28.05px]"
    >
      <img
        class="w-auto h-auto md:w-[234.51] md:h-[130.25px]"
        src="@/assets/cuddly-club.png"
        alt="CUDDLY Club"
      />
    </div>
    <div v-if="subscribed" class="mt-5 mx-[30px] mb-10">
      <h2 class="text-brand-navy">Thank you for being a CUDDLY Club Member!</h2>
    </div>
    <div v-else class="px-[30px] pt-5 pb-[30px]">
      <div class="body-text-1">
        Joining CUDDLY Club means your monthly donation goes directly towards
        helping save as many animals as possible.
      </div>
      <div class="my-5">
        <fieldset class="grid grid-cols-3 gap-x-[13px] gap-y-2.5">
          <div
            v-for="option in options"
            :key="option.id"
            class="flex justify-center bg-white rounded border px-[34.5px] py-2.5 h-[46px] cursor-pointer hover:shadow-3 font-bold border-brand-navy"
            :class="{
              'bg-brand-navy text-white': option.value === amount,
              'bg-white text-black': option.value !== amount
            }"
            @click="handleAmountClick(option.value)"
          >
            ${{ option.value }}
          </div>
          <div class="flex justify-center h-[46px]">
            <BaseInput
              id="monthly-other"
              name="subscription"
              type="text"
              placeholder="Other"
              class="bg-white rounded border border-brand-navy placeholder-black h-[46px] text-center font-bold text-base tracking-0.2 outline-none focus:outline-none focus:ring-0 focus:border-brand-navy"
              :class="{ 'bg-brand-navy text-white': otherAmount }"
              v-model="otherAmount"
              v-numeric-only:active="true"
              maxlength="4"
              mask="$###"
            />
          </div>
        </fieldset>
      </div>
      <BaseButton
        id="join-cuddly-club"
        class="uppercase py-[18px] text-sm leading-14"
        @click="joinCuddlyClub"
      >
        Join CUDDLY Club
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import BaseInput from '@/components/Base/BaseInput.vue'
import { numericOnly } from '@/directives/numericOnly'

export default {
  name: 'DashboardCuddlyClubCard',
  components: {
    BaseButton,
    BaseInput
  },
  directives: {
    numericOnly
  },
  props: {
    subscribed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      amount: 0,
      otherAmount: null,
      options: [
        {
          id: 'monthly-15',
          value: 15
        },
        {
          id: 'monthly-35',
          value: 35
        },
        {
          id: 'monthly-50',
          value: 50
        },
        {
          id: 'monthly-100',
          value: 100
        },
        {
          id: 'monthly-250',
          value: 250
        }
      ]
    }
  },
  methods: {
    joinCuddlyClub() {
      if (this.otherAmount) {
        this.amount = Number(this.otherAmount.replace('$', ''))
      }

      if (this.amount === 0) {
        this.$toast.error('Please select an amount.')

        return
      }

      const url = `${process.env.VUE_APP_CUDDLY_URL}/cuddlyclub/checkout?selectedAmount=${this.amount}`
      window.open(url, '_blank', 'noopener,noreferrer')
    },
    handleAmountClick(value) {
      this.amount = value
      this.otherAmount = null
    }
  },
  watch: {
    otherAmount() {
      if (this.otherAmount) {
        this.amount = 0
      }
    }
  }
}
</script>
