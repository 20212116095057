<template>
  <div
    class="flex flex-col md:flex-row bg-white max-w-[1100px] m-auto shadow-2 mt-5 rounded-2xl overflow-hidden mb-20"
  >
    <div class="flex flex-col md:w-1/2">
      <img
        alt="CUDDLY Club"
        src="@/assets/cuddly-club-mutt-scouts.png"
        class="w-full md:w-[380px] h-[250px] object-cover"
      />
      <div
        class="flex w-full lg:w-[380px] h-[183px] md:h-full md:items-center bg-secondary-light-blue px-[72.75px] pt-[25px] pb-[28px]"
      >
        <img
          alt="CUDDLY Club"
          src="@/assets/cuddly-club.png"
          class="max-w-[234.51px] max-h-[130px] h-full"
        />
      </div>
    </div>
    <div
      class="flex flex-col relative w-full md:w-1/2 lg:w-full h-auto lg:h-[433px]"
    >
      <BaseIcon
        name="close"
        class="absolute top-5 right-5 text-gray-900 h-6 w-6 cursor-pointer"
        @click="handleCancel"
      />
      <div
        v-if="edited"
        class="flex flex-col w-full h-full items-center justify-center py-[100px] md:py-[154px] px-[25px] lg:px-[174px]"
      >
        <h3
          class="text-brand-navy font-extrabold text-[30px] leading-45 mb-[30px]"
        >
          You are all set!
        </h3>
        <BaseButton
          id="back-to-dashboard"
          @click="handleCancel"
          variant="outlined"
          class="py-[18px] px-[66px] uppercase w-full"
        >
          Back to Dashboard
        </BaseButton>
      </div>
      <div
        v-else
        class="flex flex-col my-[50px] lg:my-[94px] mx-[45px] lg:mx-[60px]"
      >
        <h3 class="text-brand-navy font-extrabold text-[30px] leading-45">
          Change Your CUDDLY Club Subscription
        </h3>
        <span class="subtitle-1 mt-2.5 text-gray-700">
          Select or type in your desired monthly donation below.
        </span>
        <div
          class="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-5 gap-[15px] mt-10 mb-[30px]"
        >
          <div
            v-for="option in options"
            :key="option.id"
            class="flex justify-center items-center py-2.5 px-[15px] max-w-[108px] h-[47px] rounded-[5px] bg-[#F2F2F2] cursor-pointer"
            @click="handleAmountClick(option.value)"
            :class="{
              'border border-black': option.value === amount
            }"
          >
            <h6>${{ option.value }}</h6>
          </div>
          <input
            id="monthly-other"
            name="subscription"
            type="text"
            placeholder="Other"
            class="rounded-[5px] bg-[#F2F2F2] placeholder-black max-w-[108px] h-[47px] text-center font-bold text-lg leading-27 tracking-0.2 focus:ring-black focus:outline-none border-0"
            v-model="otherAmount"
            v-numeric-only:active="true"
            maxlength="4"
            v-mask="'$###'"
            @focus="amount = null"
          />
        </div>
        <div class="flex flex-col lg:flex-row gap-5 justify-end">
          <BaseButton
            id="dismiss"
            variant="outlined"
            class="py-[18px] px-[66px] uppercase w-full lg:max-w-max max-h-[50px]"
            @click="handleCancel"
          >
            Cancel
          </BaseButton>
          <BaseButton
            id="save"
            class="py-[18px] px-[66px] md:px-[20px] uppercase lg:max-w-max max-h-[50px]"
            @click="handleSave"
            :loading="loading"
            :disabled="loading"
          >
            Save Changes
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import BaseIcon from '@/components/Base/BaseIcon.vue'
import { numericOnly } from '@/directives/numericOnly'
import { mask } from 'vue-the-mask'
import {updateDonorSubscription} from "@/api/subscriptions";

export default {
  name: 'DashboardSubscriptionEdit',
  directives: {
    numericOnly,
    mask
  },
  props: {
    subscription: {
      type: Object,
      required: true
    },
  },
  components: {
    BaseButton,
    BaseIcon
  },
  data() {
    return {
      amount: 0,
      otherAmount: null,
      edited: false,
      loading: false,
      options: [
        {
          id: 'monthly-10',
          value: 10
        },
        {
          id: 'monthly-25',
          value: 25
        },
        {
          id: 'monthly-50',
          value: 50
        },
        {
          id: 'monthly-100',
          value: 100
        }
      ]
    }
  },
  methods: {
    handleCancel() {
      this.amount = 0
      this.otherAmount = null
      this.edited = false
      this.loading = false
      this.$emit('dismiss')
    },
    async handleSave() {
      this.loading = true

      if (this.otherAmount) {
        this.amount = Number(this.otherAmount.replace('$', ''))
      } else {
        this.amount = this.amount * 100
      }

      const payload = {
        subscriptionId: this.subscription.subscription_id,
        amount: this.amount,
      }

      const { status } = await updateDonorSubscription(payload)

      if (status !== 200) {
        this.$toast.error('Whoops, looks like something went wrong.')
      }

      this.edited = true
      this.loading = false
    },
    handleAmountClick(value) {
      this.amount = value
      this.otherAmount = null
    }
  }
}
</script>
