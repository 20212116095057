<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    fill="currentColor"
    stroke-width="0"
    v-bind="$attrs"
  >
    <path
      d="M18.7031 18.0094C17.6672 16.2094 15.7219 15 13.5 15H10.5C8.27812 15 6.33281 16.2094 5.29688 18.0094C6.94688 19.8469 9.3375 21 12 21C14.6625 21 17.0531 19.8422 18.7031 18.0094ZM24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12ZM12 12.75C13.8656 12.75 15.375 11.2406 15.375 9.375C15.375 7.50938 13.8656 6 12 6C10.1344 6 8.625 7.50938 8.625 9.375C8.625 11.2406 10.1344 12.75 12 12.75Z"
      fill="current"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconProfile'
}
</script>
