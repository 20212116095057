<template>
  <span
    role="role"
    class="flex items-center p-2.5 text-sm bg-coral-50 text-black rounded-md leading-21 min-h-[41px]"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'BaseAlert'
}
</script>
