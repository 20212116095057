<template>
  <div class="fixed w-full top-0 left-0 right-0" v-if="loading">
    <div class="w-1/2 relative loading"></div>
  </div>
</template>

<script>
export default {
  name: 'DashboardLoadingBar',
  computed: {
    loading() {
      return this.$store.state.loading
    }
  }
}
</script>

<style scoped>
.loading {
  background: rgba(42, 65, 91, 0);
  background: -moz-linear-gradient(
    left,
    rgba(42, 65, 91, 0) 0%,
    rgba(255, 105, 110, 1) 60%,
    rgba(42, 65, 91, 0) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(42, 65, 91, 0)),
    color-stop(60%, rgba(42, 65, 91, 1)),
    color-stop(100%, rgba(42, 65, 91, 0))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(42, 65, 91, 0) 0%,
    rgba(255, 105, 110, 1) 60%,
    rgba(42, 65, 91, 0) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(42, 65, 91, 0) 0%,
    rgba(255, 105, 110, 1) 60%,
    rgba(42, 65, 91, 0) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(42, 65, 91, 0) 0%,
    rgba(255, 105, 110, 1) 60%,
    rgba(42, 65, 91, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(42, 65, 91, 0) 0%,
    rgba(255, 105, 110, 1) 60%,
    rgba(42, 65, 91, 0) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0078d4', endColorstr='#0078d4', GradientType=1);
  height: 4px;
  -webkit-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}
</style>
