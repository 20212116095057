<template>
  <div class="w-full">
    <label
      class="block text-gray-900 text-sm font-medium leading-21 mb-2 cursor-pointer"
      :class="{ 'text-red-500': error }"
      :for="id"
    >
      <div class="inline-block">
        <span>
          {{ label }}
        </span>
        <span class="ml-1 text-sm font-normal leading-21 text-gray-500">
          {{ additionalInfo }}
        </span>
        <span v-if="required" class="ml-1 font-semibold">*</span>
      </div>
    </label>

    <Listbox
      v-model="selectedOption"
      :disabled="disabled"
      @update:modelValue="updateValue"
    >
      <div class="relative">
        <ListboxButton
          class="relative w-full h-[42px] text-left cursor-default text-sm rounded-md focus:outline-none shadow-sm focus:ring-1 focus:ring-black focus:border-black border bg-gray-50 border-gray-300 px-3"
          :class="{
            'bg-gray-100': disabled,
            'text-red-700 border-red-500 bg-red-50 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
              error,
            'animate-pulse bg-gray-100': loading
          }"
        >
          <span class="flex items-center">
            <span class="text-gray-800" v-if="selectedOption?.label">
              {{ selectedOption?.label }}
            </span>
            <span
              class="text-gray-500"
              :class="{ 'text-red-700': error }"
              v-else
            >
              {{ placeholder }}
            </span>
            <span
              class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
            >
              <BaseIcon
                name="chevron-down"
                class="h-[18px] w-[18px] text-gray-500"
                :class="{ 'text-red-700': error }"
              />
            </span>
          </span>
        </ListboxButton>
        <ListboxOptions
          class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="option in options"
            :key="option.value"
            :value="option"
            as="template"
            class="cursor-default select-none relative py-2 px-3"
            v-slot="{ active, selected }"
          >
            <li
              :class="{
                'text-gray-900': !active,
                'text-white bg-brand-navy': variant === 'primary' && active,
                'text-white bg-brand-pink': variant === 'secondary' && active
              }"
              v-bind="$attrs"
            >
              <span class="flex justify-between">
                {{ option.label }}
                <BaseIcon
                  name="check"
                  class="h-6 w-6"
                  :class="{
                    'text-brand-navy':
                      variant === 'primary' && selected && !active,
                    'text-brand-pink':
                      variant === 'secondary' && selected && !active
                  }"
                  v-show="selected"
                />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </div>
    </Listbox>
  </div>
  <span
    role="alert"
    class="block text-sm text-red-600 font-normal leading-5"
    v-if="error"
  >
    {{ error }}
  </span>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import BaseIcon from './BaseIcon.vue'

export default {
  name: 'BaseDropdown',
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    BaseIcon
  },
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    additionalInfo: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => [
        {
          label: 'Select',
          value: ''
        }
      ]
    },
    variant: {
      type: String,
      default: 'primary'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedOption: this.options.find((option) => option.value === this.value)
    }
  },
  methods: {
    updateValue() {
      this.$emit('update:modelValue', this.selectedOption.value)
    }
  },
  watch: {
    value() {
      this.selectedOption = this.options.find(
        (option) => option.value === this.value
      )
    }
  }
}
</script>
