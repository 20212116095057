<template>
  <pulse-loader
    :loading="loading"
    :color="loaderColor"
    :size="size"
    :margin="margin"
    :radius="radius"
  ></pulse-loader>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
const LOADER_VARIANTS = {
  primary: '#2a415b',
  secondary: '#ff696e'
}

export default {
  name: 'BaseLoader',
  components: { PulseLoader },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: '10px'
    },
    margin: {
      type: String,
      default: '2px'
    },
    radius: {
      type: String,
      default: '100%'
    },
    variant: {
      type: String,
      default: 'secondary'
    }
  },
  computed: {
    loaderColor() {
      return LOADER_VARIANTS[this.variant.toLowerCase()]
    }
  }
}
</script>
