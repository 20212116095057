<template>
  <div
    class="w-full md:min-w-[210px] lg:min-w-[250px] min-h-[214px] bg-white rounded-lg pt-6 pb-5 shadow-2"
  >
    <div class="flex flex-col items-center">
      <BaseIcon
        :name="icon"
        :class="customClass"
        :title="title"
        aria-hidden="true"
      />
      <div class="flex space-x-4">
        <BaseLoader v-if="isLoading" :loading="isLoading" class="mt-5" />
        <h3 v-else>
          {{ asCurrency ? formatCurrency(value) : value }}
        </h3>
      </div>
    </div>
    <div class="flex flex-col items-center">
      <span class="body-text-1 text-gray-700">{{ title }}</span>
    </div>
    <div class="flex flex-col items-center mt-[15px] px-5" v-if="!isLoading">
      <div class="flex w-full h-2 bg-red-300">
        <div
          class="h-full"
          :class="firstMetric.bgClass"
          :style="{ width: `${firstMetric.percentage}%` }"
          v-if="firstMetric.percentage > 0"
        >
          <div
            class="h-full w-full"
            :class="firstMetric.bgClass"
            :style="{ width: `${firstMetric.percentage}%` }"
          ></div>
          <div class="flex flex-col mt-1 detail-3 text-gray-500">
            <span>{{ firstMetric.title }}</span>
            <span class="-mt-1">{{ firstMetric.percentage }}%</span>
          </div>
        </div>
        <div
          class="h-full"
          :class="secondMetric.bgClass"
          :style="{ width: `${secondMetric.percentage}%` }"
          v-if="secondMetric.percentage > 0"
        >
          <div class="h-full w-full" :class="secondMetric.bgClass"></div>
          <div class="flex flex-col mt-1 detail-3 text-gray-500">
            <span>{{ secondMetric.title }}</span>
            <span class="-mt-1">{{ secondMetric.percentage }}%</span>
          </div>
        </div>
        <div
          class="h-full"
          :class="thirdMetric.bgClass"
          :style="{ width: `${thirdMetric.percentage}%` }"
          v-if="thirdMetric.percentage > 0"
        >
          <div class="h-full w-full" :class="thirdMetric.bgClass"></div>
          <div
            class="flex flex-col mt-1 detail-3 text-gray-500 text-right float-right"
          >
            <span>{{ thirdMetric.title }}</span>
            <span class="-mt-1">{{ thirdMetric.percentage }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'
import BaseLoader from '@/components/Base/BaseLoader.vue'
import currency from '@/mixins/currency'

export default {
  name: 'AnalyticalPanelMetricCard',
  components: {
    BaseIcon,
    BaseLoader
  },
  mixins: [currency],
  props: {
    icon: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#fc5e71'
    },
    size: {
      type: String,
      default: '10px'
    },
    metrics: {
      type: Array,
      default: () => []
    },
    asCurrency: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLoading() {
      return this.loading
    },
    firstMetric() {
      return this.metrics[0]
    },
    secondMetric() {
      return this.metrics[1]
    },
    thirdMetric() {
      return this.metrics[2]
    }
  }
}
</script>
