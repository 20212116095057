<template>
  <svg
    width="24"
    height="15"
    viewBox="0 0 24 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="$attrs"
  >
    <path
      d="M20.0041 0H4.02099C1.80945 0 0 1.8094 0 4.02099V10.6052C0 12.8167 1.8094 14.6262 4.02099 14.6262H19.9789C22.1905 14.6262 23.9999 12.8168 23.9999 10.6052L23.9997 4.02099C24.0248 1.80945 22.2154 0 20.0038 0H20.0041ZM22.0146 10.6051C22.0146 11.7109 21.1099 12.6156 20.0041 12.6156H4.02099C2.9152 12.6156 2.01049 11.7109 2.01049 10.6051V4.0209C2.01049 2.91511 2.9152 2.0104 4.02099 2.0104H19.9789C21.0847 2.0104 21.9894 2.91511 21.9894 4.0209L21.9892 10.6051H22.0146Z"
      fill="currentColor"
    />
    <path
      d="M5.67963 4.7243C5.67963 5.41831 5.1171 5.98083 4.4231 5.98083C3.72927 5.98083 3.16656 5.41831 3.16656 4.7243C3.16656 4.0303 3.72927 3.46777 4.4231 3.46777C5.1171 3.46777 5.67963 4.0303 5.67963 4.7243Z"
      fill="currentColor"
    />
    <path
      d="M20.8585 9.90106C20.8585 10.5951 20.2958 11.1576 19.602 11.1576C18.908 11.1576 18.3455 10.5951 18.3455 9.90106C18.3455 9.20705 18.908 8.64453 19.602 8.64453C20.2958 8.64453 20.8585 9.20705 20.8585 9.90106Z"
      fill="currentColor"
    />
    <path
      d="M12.4146 7.91602V9.04685C12.8167 8.92116 12.8669 8.61965 12.8669 8.46889C12.8418 8.21751 12.7412 8.06676 12.4146 7.91602Z"
      fill="currentColor"
    />
    <path
      d="M11.334 6.43311C11.334 6.63417 11.4344 6.75986 11.7361 6.88537V5.90527C11.51 6.00589 11.334 6.18189 11.334 6.43311Z"
      fill="currentColor"
    />
    <path
      d="M8.89631 4.19689C7.16225 5.93095 7.16225 8.7204 8.89631 10.4293C10.6304 12.1633 13.4198 12.1633 15.1287 10.4293C16.8628 8.69523 16.8628 5.90577 15.1287 4.19689C13.4199 2.46301 10.6052 2.46301 8.89631 4.19689ZM12.4147 9.77589V10.3538L11.7612 10.354V9.77607C11.2586 9.70069 10.8314 9.44931 10.555 9.07241L10.4545 8.94672L10.9068 8.36876L11.0575 8.56983C11.1832 8.72058 11.4094 8.94671 11.7612 9.04735V7.69039C11.2334 7.51439 10.6304 7.26319 10.6304 6.43386C10.6304 5.78051 11.0826 5.27776 11.7612 5.17733V4.59937H12.4146V5.17733C12.8418 5.25271 13.1936 5.47884 13.47 5.80559L13.5705 5.93128L13.093 6.50924L12.9173 6.308C12.7666 6.132 12.5906 6.00649 12.3895 5.93111V7.11225C12.892 7.26301 13.5706 7.56451 13.5706 8.46921C13.5706 8.99687 13.2691 9.65017 12.4147 9.77587L12.4147 9.77589Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDollar'
}
</script>
