<template>
  <Transition name="subscription" appear>
    <div class="flex flex-col md:flex-row bg-white rounded-lg shadow-1">
      <div
        class="flex flex-col p-5 pb-[30px] min-w-[278px] h-auto md:h-[250px] lg:h-[200px] rounded-lg"
        :class="{
          [subscriptionType.bgColor]: true,
          [subscriptionType.justify]: true
        }"
      >
        <div v-if="subscriptionType.hasHeader" class="flex gap-[6px]">
          <BaseIcon name="home" class="w-5 h-5" />
          <h6>{{ subscription.name }}</h6>
        </div>
        <div>
          <h6>{{ subscriptionType.title }}</h6>
          <span class="body-text-4">
            Subscribed since
            {{ formatDate(subscription.subscription_start, 'MMM D, YYYY') }}
          </span>
        </div>
      </div>
      <div
        class="flex flex-col w-full h-[250px] md:h-auto px-[30px] py-5 relative"
      >
        <div class="flex justify-between">
          <div class="flex items-end">
            <h3 class="font-extrabold leading-45">
              {{ formatCurrency(subscription?.amount || 0) }}
            </h3>
            <span class="body-text-1 text-gray-700 pb-2 ml-1">Monthly</span>
          </div>
          <button class="h-6 w-6" @click="handleEdit">
            <BaseIcon name="pencil-alt" class="text-brand-navy" />
          </button>
        </div>
        <div class="flex justify-between mt-3">
          <span class="subtitle-1">Payment Method</span>
          <span class="body-text-4 text-gray-700">
            Credit card ending in ****1234
          </span>
        </div>
        <div class="flex justify-between mt-3">
          <span class="subtitle-1">Last Payment</span>
          <span class="body-text-4 text-gray-700">
            {{ subscription?.current_period_start }}
          </span>
        </div>
        <div class="flex justify-between mt-3">
          <span class="subtitle-1">Next Payment</span>
          <span class="body-text-4 text-gray-700">
            {{ subscription?.current_period_end }}
          </span>
        </div>
        <!-- <div
          class="flex flex-col lg:flex-row gap-[10px] absolute bottom-[20px] right-[30px]"
        >
          <BaseButton
            id="view-donation-history"
            variant="outlined"
            class="border-gray-500 max-w-max py-2.5 px-[30px]"
          >
            <span class="tracking-2 text-10 leading-15">
              View Donation History
            </span>
          </BaseButton>
          <BaseButton id="donate-now" class="max-w-max py-2.5 px-[30px]">
            <span class="tracking-2 text-10 leading-15">Donate Now</span>
          </BaseButton>
        </div> -->
      </div>
    </div>
  </Transition>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import BaseIcon from '@/components/Base/BaseIcon.vue'
import currency from '@/mixins/currency'
import string from '@/mixins/string'
import date from '@/mixins/date'

export default {
  name: 'DashboardSubscriptionCard',
  components: {
    BaseIcon,
    BaseButton
  },
  mixins: [currency, string, date],
  props: {
    subscription: {
      type: Object,
      required: true
    },
    onEdit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      log: console.log
    }
  },
  methods: {
    handleEdit() {
      this.onEdit(this.subscription)
    }
  },
  computed: {
    subscriptionType: function () {
      const types = {
        wishpack: {
          title: 'CUDDLY Club',
          bgColor: 'bg-brand-pink',
          justify: 'justify-end',
          hasHeader: false
        },
        fullbowls: {
          title: 'Full Bowls',
          bgColor: 'bg-secondary-red',
          justify: 'justify-between',
          hasHeader: true
        }
      }

      return types[this.subscription.type] || types.fullbowls
    }
  }
}
</script>

<style scoped>
.subscription-enter-active {
  animation: fadeIn 4s ease-in both;
  animation-delay: 0.2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 0%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>
