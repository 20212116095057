<template>
  <component :is="iconComponent" aria-hidden="true" v-bind="$attrs" />
</template>

<script>
const icons = {}
const requireComponents = require.context(
  '@/components/Icons/',
  false,
  /Icon[A-Z]\w+\.vue$/
)
requireComponents.keys().forEach((fileName) => {
  const componentConfig = requireComponents(fileName)
  const componentName = fileName
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')
    .replace('Icon', '')

  icons[componentName] = componentConfig.default || componentConfig
})

export default {
  name: 'BaseIcon',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    iconComponent() {
      const name = this.name
        .toLowerCase()
        .replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
        .replace(/-./g, (x) => x[1].toUpperCase())

      return icons[name]
    }
  }
}
</script>
