<template>
  <div
    class="flex flex-col flex-shrink w-[320px] md:w-[280px] lg:w-[320px] min-h-[521px] bg-white shadow-1 rounded-lg mx-auto"
  >
    <div class="p-5 w-full h-full">
      <h6 class="text-brand-navy">{{ story.name }}</h6>
      <div class="flex my-5 gap-[4px] items-center">
        <BaseIcon name="clock" class="w-5 h-5 text-gray-300" />
        <span class="body-text-4 text-gray-700">
          Last Update: {{ formatDate(story.last_update, 'MMM DD, YYYY') }}
        </span>
      </div>
      <div class="w-full h-[240px]">
        <img
          :src="story.image"
          :alt="story.name"
          class="w-full h-full object-cover"
          @error="handleBrokenImage"
        />
      </div>
      <div class="mt-5 mb-[22px] min-h-[84px]">
        <span class="line-clamp-4 body-text-4">{{ story.description }}</span>
      </div>
      <div class="flex gap-[6px] items-center h-5">
        <BaseIcon name="home" class="w-4 h-4 text-brand-pink"></BaseIcon>
        <span class="detail-3 text-gray-700 mt-0.5">{{ story.shelter }}</span>
      </div>
      <div class="flex h-[38px] gap-[13.68px] mt-[22px]">
        <BaseButton
          :id="getDonateButtonId"
          @click="donate"
          class="text-xs leading-14 tracking-[1px]"
        >
          Donate
        </BaseButton>
        <BaseButton
          :id="getShareButtonId"
          @click="share"
          variant="outlined"
          class="text-xs leading-14 tracking-[1px] font-bold border-secondary-steel"
        >
          Share
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton'
import BaseIcon from '@/components/Base/BaseIcon'
import date from '@/mixins/date'
import campaign from '@/mixins/campaign'

export default {
  name: 'DashboardSuccessStoryCard',
  components: {
    BaseButton,
    BaseIcon
  },
  mixins: [date, campaign],
  props: {
    story: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'core',
      required: true
    }
  },
  computed: {
    getDonateButtonId() {
      return `donate-${this.story?.id}`
    },
    getShareButtonId() {
      return `share-${this.story?.id}`
    }
  },
  methods: {
    handleBrokenImage(event) {
      event.target.src = require('@/assets/paw.png')
    },
    donate() {
      window.open(
        this.getCampaignUrl(this.story),
        '_blank',
        'noopener,noreferrer'
      )
    },
    share() {
      navigator.clipboard.writeText(this.getCampaignUrl(this.story))
      this.$toast.success('Copied to clipboard!')
    }
  }
}
</script>
