<template>
  <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Recover your access
        </h2>
      </div>

      <form class="mt-8 space-y-6">
        <div class="flex justify-between mb-[30px] md:mb-2 w-full">
          <BaseInput
            id="email"
            label="Your email"
            type="email"
            v-model="email"
            placeholder="name@example.com"
            required
            @keydown.enter.prevent="sendResetLink"
            @blur="onBlur"
            :error="this.v$.email.$errors[0]?.$message"
          />
        </div>
        <BaseButton
          id="login"
          :onClick="sendResetLink"
          class="py-3"
          :disabled="isDisabled || canProceed"
          :loading="loading"
        >
              <span class="font-medium tracking-1 text-sm leading-15">
                Proceed
              </span>
        </BaseButton>
      </form>
      <div class="text-sm text-center">
        <router-link
          to="/login"
          class="font-medium text-cuddly-600 hover:text-cuddly-500"
        >
          Back to Login
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import BaseInput from '@/components/Base/BaseInput.vue'
import {email, helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {sendResetLink} from "@/api/auth";

export default {
  name: 'DashboardCuddlyClubCard',
  components: {
    BaseButton,
    BaseInput
  },
  computed: {
    canProceed() {
      return this.email.length === 0
    }
  },
  methods: {
    onBlur(e) {
      this.v$[e.target.id].$touch()
    },
    async sendResetLink() {
      if (this.isDisabled !== true && this.loading !== true) {
        this.isDisabled = true
        this.loading = true

        const { status } = await sendResetLink(this.email)

        if (status === 200) {
          this.$toast.success('Link sent successfully to your email.')

          setTimeout(() => {
            this.$router.push('/login')
          }, 1500)
        } else {
          this.$toast.error('Whoops, looks like something went wrong.')
          this.isDisabled = false
          this.loading = false
        }
      }
    }
  },
  validations: {
    email: {
      email: helpers.withMessage('Please enter a valid email address.', email),
      required: helpers.withMessage('Your email is required.', required)
    }
  },
  data() {
    return {
      email: '',
      v$: useVuelidate(),
      isDisabled: false,
      loading: false
    }
  }
}
</script>
