<template>
  <div
    class="flex flex-col flex-shrink w-[320px] md:w-[280px] lg:w-[320px] bg-white shadow-1 rounded-lg mx-auto"
    :class="{
      'min-h-[521px]': type === 'core',
      'min-h-[544px]': type === 'pantry'
    }"
  >
    <div class="w-full h-[250px]">
      <img
        :src="campaign?.image"
        :alt="campaign?.name"
        class="w-full h-full object-cover rounded-t-lg"
        @error="handleBrokenImage"
      />
    </div>
    <div class="flex flex-col flex-1 mt-2.5 mx-5 mb-5">
      <h5 class="whitespace-normal line-clamp-1 hover:line-clamp-none">
        {{ campaign?.name }}
      </h5>
      <p
        class="mx-[5px] mt-1 mb-[13px] line-clamp-2 body-text-4 text-gray-700 min-h-[42px]"
      >
        {{ campaign?.description }}
      </p>
      <div class="flex items-center w-full">
        <BaseIcon name="location-marker" class="w-4 h-4 text-brand-pink" />
        <span class="detail-3 leading-[14.4px] text-gray-700 ml-[7px] mt-0.5">
          {{ formatLocation }}
        </span>
      </div>
      <div v-if="type === 'pantry'" class="flex items-center w-full mt-3">
        <BaseIcon name="home" class="w-4 h-4 text-brand-pink" />
        <span class="detail-3 leading-[14.4px] text-gray-700 ml-[7px] mt-1">
          {{ campaign?.shelter }}
        </span>
      </div>
      <div class="mt-[23px]">
        <div class="flex w-full">
          <div class="flex flex-col flex-1">
            <div class="flex items-center">
              <BaseIcon
                name="gift"
                class="w-5 h-5 text-brand-pink mr-[4.76px]"
              />
              <h5 class="text-brand-navy">
                {{ campaign?.items_needed }}
              </h5>
            </div>
            <span class="detail-3 leading-[14.4px] text-gray-700">
              Items Still Needed
            </span>
          </div>
          <div v-if="type === 'core'" class="flex flex-col flex-1">
            <div class="flex items-center">
              <BaseIcon
                name="dollar"
                class="w-5 h-5 text-brand-pink mr-[4.76px]"
              />
              <h5 class="text-brand-navy">
                {{
                  formatCurrency(this.campaign?.donations_needed, {
                    decimals: false
                  })
                }}
              </h5>
            </div>
            <span class="detail-3 leading-[14.4px] text-gray-700">
              Donations Needed
            </span>
          </div>
        </div>
        <div v-if="type === 'core'" class="w-full mt-2.5 px-[5px]">
          <div class="flex w-full h-2 relative">
            <div
              class="h-2 bg-secondary-gold z-50 absolute"
              :style="{ width: this.campaign?.goal_reached ?? '0%' }"
            >
              <!-- empty -->
            </div>
            <div class="h-2 w-full bg-gray-100 z-10 absolute"></div>
          </div>
        </div>
        <div class="w-full mt-5 px-[5px]">
          <BaseButton :id="getDonateButtonId" @click="donate" class="py-3">
            <span class="uppercase font-bold tracking-[5px] text-sm leading-14">
              Donate Again
            </span>
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import BaseIcon from '@/components/Base/BaseIcon.vue'
import currency from '@/mixins/currency'
import campaign from '@/mixins/campaign'

export default {
  name: 'DashboardCampaignCard',
  components: {
    BaseButton,
    BaseIcon
  },
  mixins: [currency, campaign],
  props: {
    campaign: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'core',
      required: true
    }
  },
  computed: {
    formatLocation() {
      return [this.campaign?.state, this.campaign?.country]
        .filter(Boolean)
        .join(', ')
    },
    getDonateButtonId() {
      return `donate-again-${this.campaign?.id}`
    }
  },
  methods: {
    handleBrokenImage(event) {
      event.target.src = require('@/assets/paw.png')
    },
    donate() {
      window.open(
        this.getCampaignUrl(this.campaign),
        '_blank',
        'noopener,noreferrer'
      )
    }
  }
}
</script>
