<template>
  <div
    class="flex flex-col max-w-[630px] mx-auto mt-10"
    v-show="!showEditSubscription && !showCancelSubscription"
  >
    <h4>Subscription Details</h4>
    <div class="bg-white rounded-lg overflow-hidden mt-5">
      <div class="w-full h-6 bg-brand-pink"></div>
      <div class="flex flex-col p-10">
        <h4>CUDDLY Club</h4>
        <div class="body-text-1 text-gray-700">
          Subscribed Since Feb 1, 2022
        </div>
        <div class="flex justify-between mt-10">
          <span class="subtitle-3">Payment Amount</span>
          <div class="flex space-x-10">
            <span class="body-text-1 text-gray-700">${{ subscription.amount }}</span>
            <span
              class="body-text-1 text-brand-pink cursor-pointer"
              @click="handlePaymentMethodChange"
            >
              Change
            </span>
          </div>
        </div>
        <!-- Payment method not shown due to stripe not returning the last 4 digist for subscription or prices stripe objects -->
        <!-- <div class="flex justify-between mt-10">
          <span class="subtitle-3">Payment Method</span>
          <div class="flex space-x-10">
            <span class="body-text-1 text-gray-700">
              Credit card ending in ****1234
            </span>
            <span class="body-text-1 text-brand-pink cursor-pointer">
              Change
            </span>
          </div>
        </div> -->
        <div class="flex justify-between mt-10">
          <span class="subtitle-3">Next Billing Date</span>
          <span class="body-text-1 text-gray-700">{{ subscription.current_period_end }}</span>
        </div>
        <div class="flex justify-between mt-10">
          <span class="subtitle-3">Last Payment</span>
          <div class="flex space-x-10">
            <span class="body-text-1 text-gray-700">{{ subscription.current_period_start }}</span>
            <span class="body-text-1 text-gray-700">${{ subscription.amount }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col md:flex-row mt-16 md:mt-5 justify-end items-center gap-x-[53px] h-[50px]"
    >
      <BaseButton
        id="cancel"
        variant="text"
        class="uppercase max-w-max min-h-full"
        @click="handleCancel"
      >
        <span class="underline underline-offset-4">Cancel Subscription</span>
      </BaseButton>
      <BaseButton
        id="donate-now"
        class="uppercase max-w-max px-[66px] min-h-full"
      >
        Donate Now
      </BaseButton>
    </div>
  </div>

  <DashboardSubscriptionEdit
    v-show="showEditSubscription"
    @dismiss="handleDismiss"
    :subscription="subscription"
  />
  <DashboardSubscriptionCancel
    v-show="showCancelSubscription"
    :subscription="subscription"
    @dismiss="handleDismiss"
  />
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import DashboardSubscriptionEdit from '@/components/Dashboard/DashboardSubscriptionEdit.vue'
import DashboardSubscriptionCancel from '@/components/Dashboard/DashboardSubscriptionCancel.vue'
import { numericOnly } from '@/directives/numericOnly'
import { mask } from 'vue-the-mask'

export default {
  name: 'DashboardSubscriptionDetails',
  directives: {
    numericOnly,
    mask
  },
  components: {
    BaseButton,
    DashboardSubscriptionEdit,
    DashboardSubscriptionCancel
  },
  props: {
    subscription: {
      type: Object,
      required: true
    },
    editSubscription: {
      type: Boolean,
      default: false
    },
    cancelSubscription: {
      type: Boolean,
      default: false
    },
    onDismiss: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      showEditSubscription: false,
      showCancelSubscription: false
    }
  },
  methods: {
    handleCancel() {
      this.showCancelSubscription = true
    },
    handlePaymentMethodChange() {
      this.showEditSubscription = true
    },
    handleDismiss() {
      this.showEditSubscription = false
      this.showCancelSubscription = false
      this.$emit('dismiss')
    }
  }
}
</script>
