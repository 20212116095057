<template>
  <div class="grid grid-cols-12 bg-white">
    <div class="w-full col-span-12 lg:col-span-5">
      <div class="flex flex-col items-center px-5 lg:px-[222px]">
        <div class="w-[388px]">
          <div class="flex w-full justify-center">
            <img
              src="@/assets/cuddly.png"
              alt="CUDDLY"
              class="w-[136px] h-6 mt-[77px] lg:mt-[120px] mb-10"
            />
          </div>
          <h5 class="font-bold text-xl md:text-2xl text-center">
            Already have an account?
            <br />
            Log in below.
          </h5>
          <form
            class="flex flex-col mb-[42px] md:gap-5 mt-5"
            novalidate="true"
            @submit="login"
          >
            <BaseAlert v-if="wrongCredentials">
              Something's not right with your username or password.
            </BaseAlert>
            <BaseInput
              id="email"
              label="Your email"
              type="email"
              v-model="email"
              placeholder="name@example.com"
              required
              @keydown.enter.prevent="login"
              @blur="onBlur"
              :error="this.v$.email.$errors[0]?.$message"
            />
            <BaseInput
              id="password"
              label="Password"
              type="password"
              v-model="password"
              placeholder="password"
              required
              @keydown.enter.prevent="login"
              @blur="onBlur"
              :error="this.v$.password.$errors[0]?.$message"
            />
            <div class="flex justify-between mb-[30px] md:mb-2 w-full">
              <div class="w-1/2">
                <BaseCheckbox
                  id="remember"
                  title="Remember me"
                  variant="secondary"
                  v-model="remember"
                >
                  Remember me
                </BaseCheckbox>
              </div>
              <div class="w-1/2 text-right">
                <router-link
                  to="/forgot-password"
                  class="underline text-brand-navy body-text-4"
                >
                  Forgot Your Password?
                </router-link>
              </div>
            </div>
            <BaseButton
              id="login"
              :onClick="login"
              :disabled="isDisabled"
              :loading="loading"
              class="py-3"
            >
              <span class="font-medium tracking-1 text-sm leading-15">
                Log In
              </span>
            </BaseButton>
          </form>
        </div>
      </div>
    </div>
    <div class="w-full col-span-12 lg:col-span-7">
      <div class="flex lg:flex-1 lg:h-screen">
        <img
          src="@/assets/login.png"
          class="w-full h-[540px] md:h-full object-cover"
        />
        <div class="absolute bottom-0 flex flex-col text-white px-5 md:mx-36">
          <h2
            class="font-extrabold text-4xl mb-3 tracking-wide md:text-7xl lg:text-8xl"
          >
            Welcome to CUDDLY!
          </h2>
          <h3 class="font-bold text-3xl md:text-5xl lg:text-6xl">
            Where animals get happy
          </h3>
          <h6 class="font-bold text-lg mt-8 mb-20 md:text-2xl xl:mb-52">
            CUDDLY is the only dedicated crowdfunding platform built
            specifically for animal welfare organizations worldwide.
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseAlert from '@/components/Base/BaseAlert.vue'
import BaseInput from '@/components/Base/BaseInput.vue'
import BaseButton from '@/components/Base/BaseButton.vue'
import BaseCheckbox from '@/components/Base/BaseCheckbox.vue'
import useVuelidate from '@vuelidate/core'
import { email, required, helpers } from '@vuelidate/validators'
import {donorExternalLogin, donorLogin} from '@/api/auth'

export default {
  name: 'LoginView',
  components: { BaseAlert, BaseInput, BaseButton, BaseCheckbox },
  data() {
    const remember = localStorage.getItem('remember') === 'true'

    return {
      loading: false,
      isDisabled: false,
      v$: useVuelidate(),
      email: remember ? localStorage.getItem('email') : '',
      password: '',
      remember,
      wrongCredentials: false
    }
  },
  watch: {
    remember() {
      if (this.remember) {
        localStorage.setItem('email', this.email)
        localStorage.setItem('remember', this.remember)
      } else {
        localStorage.removeItem('email')
        localStorage.removeItem('remember')
      }
    }
  },
  methods: {
    onBlur(e) {
      this.v$[e.target.id].$touch()
    },
    async login() {
      if (this.isDisabled !== true && this.loading !== true) {
        this.isDisabled = true
        this.loading = true
        this.wrongCredentials = false

        const loginResponse = await donorLogin(this.email, this.password)

        if (
          loginResponse.status === 200 &&
          loginResponse?.data?.user?.role === 'user'
        ) {
          const userInfo = loginResponse.data.user

          this.$store.dispatch('afterSuccessLogin', userInfo)
          this.$storage.setStorageSync('userData', userInfo)
          this.$router.go()
        } else {
          this.isDisabled = false
          this.loading = false
          this.wrongCredentials = true
        }
      }
    },
    async doDonorExternalLogin(loginString) {
      if (this.isDisabled !== true && this.loading !== true) {
        this.isDisabled = true
        this.loading = true
        this.wrongCredentials = false

        const loginResponse = await donorExternalLogin(loginString)

        if (
          loginResponse.status === 200 &&
          loginResponse?.data?.user?.role === 'user'
        ) {
          const userInfo = loginResponse.data.user

          this.$store.dispatch('afterSuccessLogin', userInfo)
          this.$storage.setStorageSync('userData', userInfo)
          this.$router.go()
        } else {
          this.isDisabled = false
          this.loading = false
          this.wrongCredentials = true
        }
      }
    }
  },
  validations: {
    email: {
      email: helpers.withMessage('Please enter a valid email address.', email),
      required: helpers.withMessage('Your email is required.', required)
    },
    password: {
      required: helpers.withMessage('Password is required.', required)
    }
  },
  beforeCreate() {
    if (this.$store.getters.getUser !== null) {
      this.$router.push('/')
    }
  },
  async created() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let loginString = ''

    if (urlParams.has('external') && urlParams.get('external').length > 0) {
      loginString = urlParams.get('external');

      await this.doDonorExternalLogin(loginString)
    }
  }
}
</script>
