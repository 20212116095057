import {get, post, put, destroy} from '@/config/api'

const getSubscriptions = async () => {
  try {
    const { status, data } = await get('/api/donor/subscriptions')

    return { status, data }
  } catch (e) {
    const { status, data } = e.response

    return { status, errors: data }
  }
}

const updateDonorSubscription = async (subscription) => {
  try {
    const payload = {
      amount: subscription.amount,
      subscriptionId: subscription.subscriptionId
    }

    const { status, data } = await put('/api/donor/subscriptions', payload)

    return { status, data }
  } catch (e) {
    const { status, data } = e.response

    return { status, errors: data }
  }
}

const pauseSubscription = async (monthsToPause, subscriptionId) => {
  try {
    const payload = {
      monthsToPause,
      subscriptionId
    }

    const { status, data } = await post('/api/donor/subscriptions/pause', payload)

    return { status, data }
  } catch (e) {
    const { status, data } = e.response

    return { status, errors: data }
  }
}

const cancelSubscription = async (subscriptionId) => {
  try {
    const { status, data } = await destroy('/api/donor/subscriptions/' + subscriptionId)

    return { status, data }
  } catch (e) {
    const { status, data } = e.response

    return { status, errors: data }
  }
}

export { getSubscriptions, updateDonorSubscription, pauseSubscription, cancelSubscription }
